@import '../shared/variables';
@import '../shared/breakpoints';
@import '../shared/mixins/typography.scss';

.h1 {
  @include sm-h1;

  text-transform: uppercase;
  color: $blue-dark;

  @include respond(md) {
    @include md-h1;

    text-transform: uppercase;
    color: $blue-dark;
  }

  &.centered {
    text-align: center;
  }

  &.white {
    color: $white;
  }

  &.black {
    color: $black;
  }

  &.uppercase {
    text-transform: uppercase;
  }
}

.h2 {
  @include sm-h2;

  color: $blue-dark;

  @include respond(md) {
    @include md-h2;

    color: $blue-dark;
  }

  &.white {
    color: $white;
  }

  &.centered {
    text-align: center;
  }

  &.break-all {
    word-break: break-all;
  }

  &.uppercase {
    text-transform: uppercase;
  }
}

.h3 {
  @include sm-h3;

  @include respond(md) {
    @include md-h3;
  }

  &.white {
    color: $white;
  }

  &.centered {
    text-align: center;
  }
}

.h4 {
  @include sm-h4;

  color: $blue-dark;

  @include respond(md) {
    @include md-h4;

    color: $blue-dark;
  }

  &.white {
    color: $white;
  }

  &.centered {
    text-align: center;
  }
}

.link {
  color: $blue-bright;

  @include sm-link;

  @include respond(md) {
    @include md-link;
  }

  &.white {
    color: $white;
  }

  &:hover {
    cursor: pointer;
  }

  &.centered {
    text-align: center;
    color: $blue-dark;
  }

  &.dark {
    color: $blue-dark;
  }

  &.centered {
    text-align: center;
  }

  &.transparent {
    border: none;
    outline: none;
    background: transparent;
  }
}

.p {
  @include sm-body;
  color: $blue-dark;

  @include respond(md) {
    @include md-body;
    color: $blue-dark;
  }

  &.centered {
    text-align: center;
  }
}

.a {
  @include sm-a;

  @include respond(md) {
    @include md-a;
  }
}

body {
  @include sm-body;

  @include respond(md) {
    @include md-body;
  }

  *,
  .text {
    font-size: 1.6rem;
    font-family: inherit;
    color: inherit;
    line-height: inherit;
    letter-spacing: inherit;
  }

  .text-centered {
    text-align: center;
  }

  .text-white {
    color: $white;
  }

  .text-black {
    color: $black;
  }

  .text-uppercase {
    text-transform: uppercase;
  }

  .font-weight-bold {
    font-family: $font-quicksand;
    font-weight: 700;
  }
}
