@import "../variables.scss";
@import "../breakpoints.scss";

@mixin tablet-max-content-width {
    @include respond(600px) {
        max-width: 600px;
        margin: 0 auto;
    }


    @include respond(1024px) {
        max-width: none;
        margin: 0;
    }
}
