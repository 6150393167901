@import '../shared/breakpoints';
@import '../shared/variables';

.button {
  &,
  &:link,
  &:visited {
    font-family: $font-sen;
    font-size: 16px;
    font-weight: 400;
    letter-spacing: 0.5px;
    padding: 1rem 1.5rem;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    transition: all 0.2s;
    position: relative;
    color: $white;
    border: none;
    cursor: pointer;
    border-radius: 24px;
    min-width: 240px;
    min-height: 40px;

    @include respond(sm) {
      height: 48px;
    }
  }

  &:hover {
    @include respond(sm) {
      transform: translateY(-6px);
    }
  }

  &.block {
    display: block;
  }

  &:active,
  &:focus {
    @include respond(sm) {
      outline: none;
      transform: translateY(-1px);
    }
  }

  &:disabled,
  &.disabled {
    @include respond(sm) {
      cursor: not-allowed;
      transform: none;
    }
  }

  &--cta {
    &,
    &:link,
    &:visited {
      background-color: #ff7695;
    }

    &:hover,
    &:active,
    &:focus {
      @include respond(sm) {
        background-color: #e3365d;
      }
    }

    &:disabled,
    &.disabled {
      opacity: 0.5;
      background-color: darken(#e3365d, 20%);
    }
  }

  &--outlined {
    &,
    &:link,
    &:visited {
      line-height: 1;
      color: $blue-dark;
      background-color: $white;
      border: solid 2px $blue-dark;
    }
  }

  &--small {
    min-width: 11.6rem;
    min-height: 30px;
    font-size: 14px;
    padding: 0.5rem 1.5rem;
  }

  &--tiny {
    min-width: 11.6rem;
    min-height: 20px;
    font-size: 14px;
    padding: 0.5rem;
    height: 30px;
  }

  &--noHoverEffect {
    &:hover {
      @include respond(sm) {
        transform: none;
      }
    }
  }

  &--borderRadiusSmall {
    border-radius: 0.4rem !important;
  }
}

.transparentButton {
  border: none;
  outline: none;
  background: transparent;
}

.button:link,
.button:visited {
  display: inline-flex;
  align-items: center;
  justify-content: center;
}
