@import './typography';
@import './button';
@import './grid';
@import './normalize';
@import './spacings';
@import './toastify';
@import './cookiebot';
@import '@assets/scss/v2/main';

html,
body,
#__next {
  height: 100%;
}

.onfido-sdk-ui-Modal-inner {
  position: relative !important;
  height: 37.5em !important;
}
