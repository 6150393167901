@import '../shared/variables.scss';
@import '../shared/breakpoints.scss';
@import '../shared/content-screen-edge-distance.scss';
@import '../shared/mixins/desktop-max-content-width.scss';
@import '../shared/mixins/tablet-max-content-width.scss';

$spacings-map: (
  '2xs': $spacing-2xs,
  'xs': $spacing-xs,
  's': $spacing-s,
  'm': $spacing-m,
  'l': $spacing-l,
  'xl': $spacing-xl,
  '2xl': $spacing-2xl,
  '3xl': $spacing-3xl,
  '4xl': $spacing-4xl,
  '5xl': $spacing-5xl,
  '6xl': $spacing-6xl,
);

.content-screen-edge-distance {
  @include content-screen-edge-distances;
}

.remove-content-screen-edge-distance {
  @include remove-content-screen-edge-distances;
}

.tablet-max-content-width {
  @include tablet-max-content-width;
}

.desktop-max-content-width {
  @include desktop-max-content-width;
}


@each $spacing-name, $spacing-size in $spacings-map {
  .xs-mb-#{$spacing-name} {
    @include respond(xs) {
      margin-bottom: $spacing-size !important;
    }
  }

  .xs-mt-#{$spacing-name} {
    @include respond(xs) {
      margin-top: $spacing-size !important;
    }
  }

  .xs-mr-#{$spacing-name} {
    @include respond(xs) {
      margin-right: $spacing-size !important;
    }
  }

  .xs-ml-#{$spacing-name} {
    @include respond(xs) {
      margin-left: $spacing-size !important;
    }
  }
}

@each $spacing-name, $spacing-size in $spacings-map {
  .sm-mb-#{$spacing-name} {
    margin-bottom: $spacing-size !important;
  }

  .sm-mt-#{$spacing-name} {
    margin-top: $spacing-size !important;
  }

  .sm-mr-#{$spacing-name} {
    margin-right: $spacing-size !important;
  }

  .sm-ml-#{$spacing-name} {
    margin-left: $spacing-size !important;
  }
}

@each $spacing-name, $spacing-size in $spacings-map {
  .md-mb-#{$spacing-name} {
    @include respond(md) {
      margin-bottom: $spacing-size !important;
    }
  }

  .md-mt-#{$spacing-name} {
    @include respond(md) {
      margin-top: $spacing-size !important;
    }
  }

  .md-mr-#{$spacing-name} {
    @include respond(md) {
      margin-right: $spacing-size !important;
    }
  }

  .md-ml-#{$spacing-name} {
    @include respond(md) {
      margin-left: $spacing-size !important;
    }
  }
}

.xs-mb-0 {
  margin-bottom: 0 !important;
}

.xs-mt-0 {
  margin-top: 0 !important;
}

.xs-mr-0 {
  margin-right: 0 !important;
}

.xs-ml-0 {
  margin-left: 0 !important;
}

.sm-mb-0 {
  @include respond(sm) {
    margin-bottom: 0 !important;
  }
}

.sm-mt-0 {
  @include respond(sm) {
    margin-top: 0 !important;
  }
}

.sm-mr-0 {
  @include respond(sm) {
    margin-right: 0 !important;
  }
}

.sm-ml-0 {
  @include respond(sm) {
    margin-left: 0 !important;
  }
}

.md-mb-0 {
  @include respond(md) {
    margin-bottom: 0 !important;
  }
}

.md-mt-0 {
  @include respond(md) {
    margin-top: 0 !important;
  }
}

.md-mr-0 {
  @include respond(md) {
    margin-right: 0 !important;
  }
}

.md-ml-0 {
  @include respond(md) {
    margin-left: 0 !important;
  }
}
