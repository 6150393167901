@import "./variables.scss";

$screen-edge-distance-up-to-380: $spacing-m;
$screen-edge-distance-up-to-768: $spacing-l;
$screen-edge-distance-up-to-1023: 3vw;
$screen-edge-distance-from-1024: $spacing-xl;

@mixin screen-edge-distance-up-to-380 {
    @media only screen and (max-width: 380px) {
        padding-left: $screen-edge-distance-up-to-380;
        padding-right: $screen-edge-distance-up-to-380;
    }
}

@mixin screen-edge-distance-up-to-768 {
    @media screen and (min-width: 381px) and (max-width: 768px) {
        padding-left: $screen-edge-distance-up-to-768;
        padding-right: $screen-edge-distance-up-to-768;
    }
}

@mixin screen-edge-distance-up-to-1023 {
    @media screen and (min-width: 789px) and (max-width: 1023px) {
        padding-left: $screen-edge-distance-up-to-1023;
        padding-right: $screen-edge-distance-up-to-1023;
    }
}

@mixin screen-edge-distance-from-1024 {
    @media screen and (min-width: 1024px) {
        padding-left: $screen-edge-distance-from-1024;
        padding-right: $screen-edge-distance-from-1024;
    }
}

@mixin content-screen-edge-distances-up-to-1024 {
    @include screen-edge-distance-up-to-380;
    @include screen-edge-distance-up-to-768;
    @include screen-edge-distance-up-to-1023;
}

@mixin content-screen-edge-distances {
    @include screen-edge-distance-up-to-380;
    @include screen-edge-distance-up-to-768;
    @include screen-edge-distance-up-to-1023;
    @include screen-edge-distance-from-1024;
}

@mixin remove-content-screen-edge-distances {
    @media only screen and (max-width: 380px) {
        margin-left: -1 * $screen-edge-distance-up-to-380;
        margin-right: -1 * $screen-edge-distance-up-to-380;
    }

    @media screen and (min-width: 381px) and (max-width: 768px) {
        margin-left: -1 * $screen-edge-distance-up-to-768;
        margin-right: -1 * $screen-edge-distance-up-to-768;
    }

    @media screen and (min-width: 789px) and (max-width: 1023px) {
        margin-left: -1 * $screen-edge-distance-up-to-1023;
        margin-right: -1 * $screen-edge-distance-up-to-1023;
    }

    @media screen and (min-width: 1024px) {
        margin-left: -1 * $screen-edge-distance-from-1024;
        margin-right: -1 * $screen-edge-distance-from-1024;
    }
}
