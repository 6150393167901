@import '@assets/scss/v2/main';

.CookieDeclaration {
  padding: 2.4rem;
  overflow-x: scroll;
  margin-top: 8rem;

  &::-webkit-scrollbar {
    width: 0.6rem;
  }

  &::-webkit-scrollbar-track {
    background: $white-smoke;
    margin: 0.5rem;
    border-radius: 3rem;
  }

  &::-webkit-scrollbar-thumb {
    border-radius: 3rem;
    border: 0.3rem solid $white-smoke;
  }

  .CookieDeclarationTypeHeader {
    font-size: 2rem;
    margin-bottom: 2rem;
  }

  #CookieDeclarationUserStatusLabelConsentId {
    overflow-wrap: anywhere;
  }

  @include respond(m) {
    padding: 4rem 8rem;
    width: 100%;
  }

  a {
    color: $blue-bright;
    text-decoration: underline;
    &:hover {
      color: $blue-dark;
    }
  }
}
