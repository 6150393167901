@import '../variables.scss';

@mixin sm-h1 {
  font-size: 2rem;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.33;
  letter-spacing: normal;
  font-family: $font-quicksand;
}

@mixin md-h1 {
  font-size: 4rem;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.25;
  letter-spacing: 2px;
  font-family: $font-quicksand;
}

@mixin sm-h2 {
  font-family: $font-quicksand;
  font-size: 2rem;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.6;
  letter-spacing: 0.5px;
}

@mixin md-h2 {
  font-family: $font-quicksand;
  font-size: 4rem;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.33;
  letter-spacing: 0.5px;
}

@mixin sm-h3 {
  font-size: 1.8rem;
  line-height: 1.33;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: normal;
  font-family: $font-quicksand;
}

@mixin md-h3 {
  font-size: 3.2rem;
  line-height: 1.25;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: normal;
  font-family: $font-quicksand;
}

@mixin sm-h4 {
  font-size: 1.6rem;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  font-family: $font-quicksand;
}

@mixin md-h4 {
  font-size: 2.4rem;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.33;
  letter-spacing: normal;
  font-family: $font-quicksand;
}

@mixin sm-link {
  font-family: $font-sen;
  text-decoration: underline;
  font-size: 1.6rem;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0.5px;
}

@mixin md-link {
  font-family: $font-sen;
  text-decoration: underline;
  font-size: 1.6rem;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0.5px;
}

@mixin sm-body {
  font-family: $font-quicksand;
  font-size: 1.6rem;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  font-weight: 400;
}

@mixin md-body {
  font-family: $font-quicksand;
  font-size: 1.8rem;
  font-style: normal;
  line-height: 1.33;
  letter-spacing: normal;
  font-weight: 400;
}

@mixin sm-a {
  font-family: $font-quicksand;
  font-size: 1.6rem;
  font-style: normal;
  line-height: 1.33;
  letter-spacing: normal;
  color: $blue-bright;
  font-weight: 700;

  &:hover {
    text-decoration: underline;
  }
}

@mixin md-a {
  font-family: $font-quicksand;
  font-size: 1.8rem;
  font-style: normal;
  line-height: 1.33;
  letter-spacing: normal;
  color: $blue-bright;
  font-weight: 700;

  &:hover {
    text-decoration: underline;
  }
}
