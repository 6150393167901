@import '../shared/breakpoints.scss';
@import '../shared/variables.scss';

.grid {
  max-width: $max-content-width;
  margin: 0 auto;
  width: 100%;

  &-p {
    max-width: $max-content-width;
    margin: 0 auto;
    padding-left: 24px;
    padding-right: 24px;

    @include respond(sm) {
      padding-left: 32px;
      padding-right: 32px;
    }

    @include respond(lg) {
      width: $max-content-width;
      max-width: $max-content-width;
    }
  }
}

.row {
  display: flex;
  flex-wrap: wrap;

  &-p {
    display: flex;
    flex-wrap: wrap;
    margin-right: -1 * $xs-col-gap;
    margin-left: -1 * $xs-col-gap;

    @include respond(sm) {
      margin-right: -1 * $sm-col-gap;
      margin-left: -1 * $sm-col-gap;
    }
  }
}

.row--centered {
  justify-content: center;
}

$grid-column-size: 12;

@for $column-size from 0 through $grid-column-size {
  .col-xs-#{$column-size} {
    width: 100% * $column-size / $grid-column-size;

    &-p {
      width: 100% * $column-size / $grid-column-size;
      padding-left: $xs-col-gap;
      padding-right: $xs-col-gap;
    }
  }
}

@for $column-size from 0 through $grid-column-size {
  .col-sm-#{$column-size} {
    @include respond(sm) {
      width: 100% * $column-size / $grid-column-size;

      &-p {
        width: 100% * $column-size / $grid-column-size;
        padding-left: $sm-col-gap;
        padding-right: $sm-col-gap;
      }
    }
  }
}

@for $column-size from 0 through $grid-column-size {
  .col-md-#{$column-size} {
    @include respond(md) {
      width: 100% * $column-size / $grid-column-size;

      &-p {
        width: 100% * $column-size / $grid-column-size;
        padding-left: $md-col-gap;
        padding-right: $md-col-gap;
      }

      &-pl {
        width: 100% * $column-size / $grid-column-size;
        padding-left: $md-col-gap;
      }
    }
  }
}

@for $column-size from 0 through $grid-column-size {
  .col-lg-#{$column-size} {
    @include respond(lg) {
      width: 100% * $column-size / $grid-column-size;

      &-p {
        width: 100% * $column-size / $grid-column-size;
        padding-left: $lg-col-gap;
        padding-right: $lg-col-gap;
      }
    }
  }
}
